import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar/Navbar';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';  
import About from './components/About/About';  
import Gallery from './components/Gallery/Gallery'; 
import Clients from './components/Clients/Clients'; 
import Services from './components/Services/Services';
import Pricing from './components/Pricing/Pricing';  
import './App.css';

// Create a new LandingPage component that contains all components
const LandingPage = () => {
  return (
    <>
      <Home />
      {/* <About /> */}
      {/* <Gallery /> */}
      {/* <Services /> */}
      <Clients />
      <Pricing />
      <Contact />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          {/* Landing page route that shows all components */}
          <Route exact path="/" element={<LandingPage />} />
          
          {/* Individual routes for separate page access */}
          <Route exact path="/about" element={<About />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/clients" element={<Clients />} />
          {/* <Route exact path="/services" element={<Services />} /> */}
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;