import React from 'react';
import { Facebook, Twitter, Mail, MapPin, Phone, Instagram } from 'lucide-react';
import './index.css'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-grid">
          {/* About Us Section */}
          <div>
            <h2 className="section-heading">About us</h2>
            <p className="about-text">
              HungerOps an Restuarent software provide company working with hotels.
            </p>
          </div>

          {/* Quick Links Section */}
          <div>
            <h2 className="section-heading">Quick Links</h2>
            <ul className="quick-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About us</a></li>
              <li><a href="/gallery">Gallery</a></li>
              <li><a href="/clients">Clients</a></li>
              {/* <li><a href="/services">Services</a></li> */}
              <li><a href="/pricing">Pricing</a></li>
              <li><a href="/contact">Contact us</a></li>
            </ul>
          </div>

          {/* Corporate Office Section */}
          <div>
            <h2 className="section-heading">Corporate Office</h2>
            <div className="contact-info">
              <div className="contact-item">
                <Phone className="contact-icon" />
                <a href="tel:+919391929181" className="contact-text">
                  +91 9391929181
                </a>
              </div>
              <div className="contact-item">
                <Mail className="contact-icon" />
                <a href="mailto:hugerops.in@gmail.com" className="contact-text">
                    hugerops.in@gmail.com
                </a>
              </div>
              <div className="contact-item">
                <MapPin className="contact-icon" />
                <span className="contact-text">
                  Vasavi Nagar, Secundrabad, Hyderabad, Pin-500020
                </span>
              </div>
            </div>

            {/* Social Media Icons */}
            <div className="social-links">
              {/* <a href="#" className="social-icon icon-animate">
                <Facebook />
              </a>
              <a href="#" className="social-icon icon-animate">
                <Twitter />
              </a> */}
              <a href="#" className="social-icon icon-animate">
                <Instagram />
              </a>
              <a href="mailto:hugerops.in@gmail.com" className="social-icon icon-animate">
                <Mail />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="copyright">
          <p>© Copyright 2024 All rights reserved by HungerOps</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;