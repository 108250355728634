// Home.jsx
import React from 'react';
import { Phone, MessageCircle } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';
import Video from "../../videos/6011627_Person_People_1280x720.mp4";
import './index.css';

const Home = () => {
  return (
    <div className="home-container">
      <div className="banner-section">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={Video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <div className="content-container">
            <h1 className="main-heading">
              Welcome to HungerOps
            </h1>
            
            <p className="slogan">
              We simplify order management for hotels
            </p>
            
            <div className="contact-buttons">
              <a href="tel:+919391929181" className="contact-btn call-btn">
                <Phone className="icon" />
                <span>Call Us</span>
              </a>
              
              <a 
                href="https://wa.me/9391929181" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="contact-btn whatsapp-btn"
              >
                <FaWhatsapp className="icon" />
                <span>WhatsApp Us</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;